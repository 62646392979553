import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios';
import { paginateArray, sortCompare } from '@/@fake-db/utils'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'user', sortable: true },
    { key: 'username', sortable: true },
    { key: 'nama', sortable: true },
    { key: 'role', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const  url = localStorage.getItem('baseapi');
  const  pengguna = JSON.parse(localStorage.getItem('userData'));

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchUsers = (ctx, callback) => {
    axios.get(url+'user', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      headers: {
          "Content-type": "application/json",
          "xth": pengguna.token,
          }
      })
      .then(response => {
        // const { users, total } = response.data.data
        var userss = response.data.data
        var total = response.data.data

        const q = searchQuery.value

        const queryLowered = q.toLowerCase()
        const filteredData = userss.filter(
          user =>
            /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
            (user.username.toLowerCase().includes(queryLowered) || user.nama.toLowerCase().includes(queryLowered))
        )
        console.log(filteredData)
        var users = filteredData
        getakses()
        // const sortedData = filteredData.sort(sortCompare(sortBy))
        // if (sortDesc) sortedData.reverse()
        // var users= filteredData
        // var total= filteredData.length
        // const uhuy = ref([])
        // const jumlah = ref([])
        // uhuy.value = response.data.data
        // jumlah.value = response.data.data
        // const users = uhuy.value
        // const total = jumlah
        
        // var woi = response.data.data
        // console.log(woi.length)
        // total.value = response.data.data
        // const users

        callback(users)
        totalUsers.value = total.length
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const akses = ref([])
  const getakses = () => {
    axios.get(url+'hak_akses', {
      headers: {
          "Content-type": "application/json",
          "xth": pengguna.token,
          }
      })
      .then(response => {
        // const { users, total } = response.data.data
        var userss = response.data.data
        akses.value = userss
        console.log(akses.value)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  
  

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    getakses,
    akses
  }
}
